<template>
  <div class="container">
    <!-- <div class="text main">支付成功</div> -->
    <div class="text main">Thanh toán thành công.</div>
    <!-- <div class="text sub" style="margin-top: 8vw;">请返回游戏</div> -->
    <div class="text sub" style="margin-top: 8vw;">Làm ơn quay lại trò chơi.</div>
    <!-- <div class="text sub" style="margin-top: 8vw;">如没有到账，请联系客服</div> -->
    <div class="text sub" style="margin-top: 8vw;">Nếu không nhận được, vui lòng liên hệ với dịch vụ khách hàng.</div>
    <!-- <div class="text sub" style="margin-top: 12vw;">客服</div> -->
    <div class="text sub" style="margin-top: 12vw;">dịch vụ khách hàng</div>
    <div class="service-box" v-for="(one, index) in list" :key="index">
      <div class="info">
        <img class="icon" :src="one.icon">
        <div class="detail">
          <div class="nickname">{{ one.nickname }}</div>
          <div class="account">{{ one.account }}</div>
        </div>
      </div>
      <!-- <div class="contact" @click="contact(index)">联系</div> -->
      <div class="contact" @click="contact(index)"><div>liên hệ với</div></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          kind: 'facebook',
          icon: require('@/assets/pay/facebook.png'),
          account: 'Follow',
          nickname: 'Fanpage',
          url: 'https://www.facebook.com/profile.php?id=100085627190966'
        },
        {
          kind: 'zalo',
          icon: require('@/assets/pay/zalo.png'),
          account: '327140916',
          nickname: 'Bắn cá cá',
          url: 'https://zalo.me/327140916'
        }
      ]
    }
  },
  methods: {
    contact (index) {
      const url = this.list[index].url
      location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16vh;
  .text {
    font-size: 6vw;
    margin: 0 4vw;
    &.main {
      font-size: 6vw;
    }
    &.sub {
      font-size: 4.5vw;
    }
  }
  .service-box {
    border: 1px solid #dddddd;
    background-color: #eeeeee;
    border-radius: 2vw;
    margin: 2vw 0;
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info {
      display: flex;
      justify-content: start;
      align-items: center;
      .icon {
        height: 16vw;
        width: 16vw;
      }
      .detail {
        margin-left: 2vw;
        .nickname {
          font-size: 5vw;
        }
        .account {
          font-size: 4.5vw;
        }
      }
    }
    .contact {
      border: 1px solid #dddddd;
      background-color: #ffffff;
      border-radius: 2vw;
      margin-right: 2vw;
      width: 24vw;
      height: 12vw;
      text-align: center;
      vertical-align: middle;
      font-size: 4vw;
      display: flex;
      justify-content: center;
      align-items: center;
      &:active {
        background-color: #eeeeee;
      }
    }
  }
}
</style>
